<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; margin-top: 20px">
      <div class="md-layout-item md-size-100 mt-5">
          <CheckboxComponent
            class=""
            label-class="font-bold"
            :label-outline="false"
            :block="true"
            :items="[{id: 1, label: 'No Prior Experience'}]"
            @input="getIsExperience"
         
            />
        </div>
        <div v-if="is_experienced">
          <div  class="md-layout" v-for="(v, index) in $v.experience_details.$each.$iter" v-bind:key="index">
        
          <div class="md-layout-item md-size-100 mt-5">
            <InputFieldComponent
                label="Company Name"
                class="w-full"
                placeholder="Enter Company Name"
                v-model.trim="v.company.$model"
                :message="!v.company.required && v.company.$dirty ? 'Field is required' : null"
                
            />
          </div>
          <div class="md-layout-item md-size-100 mt-5">
            <InputFieldComponent
                label="Designation"
                class="w-full"
                placeholder="Your Designation"
                v-model.trim="v.designation.$model"
                :message="!v.designation.required && v.designation.$dirty ? 'Field is required' : null"
                
            />
          </div>
          <div class="md-layout-item md-size-50 mt-5" >
            <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block">From</label>
            <div class="flex">
              <div class="relative">
                <Datepicker 
                  placeholder="Enter From Date"
                  :format="customFormatter"
                  :clearButton="true"
                  input-class="w-54 m-1 py-3 pl-3 rounded outline-gray-400"
                  v-model.trim="v.from_date.$model"
                  :message="!v.from_date.required && v.from_date.$dirty ? 'Field is required' : null"
                  />
                  <div class="text-xs text-danger" v-if="!v.from_date.required && v.from_date.$dirty">{{ !v.from_date.required && v.from_date.$dirty ? 'Field is required' : null }}</div>
                  <svg class="w-5 date-picker-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                    <path d="M5 0L5 4L7 4L7 0 Z M 17 0L17 4L19 4L19 0 Z M 1 3C0.449219 3 0 3.449219 0 4L0 7C0 7.550781 0.449219 8 1 8L1 24L23 24L23 8C23.550781 8 24 7.550781 24 7L24 4C24 3.449219 23.550781 3 23 3L20 3L20 5L16 5L16 3L8 3L8 5L4 5L4 3 Z M 3 8L21 8L21 22L3 22 Z M 5 10L5 12L7 12L7 10 Z M 9 10L9 12L11 12L11 10 Z M 13 10L13 12L15 12L15 10 Z M 17 10L17 12L19 12L19 10 Z M 5 14L5 16L7 16L7 14 Z M 9 14L9 16L11 16L11 14 Z M 13 14L13 16L15 16L15 14 Z M 17 14L17 16L19 16L19 14 Z M 5 18L5 20L7 20L7 18 Z M 9 18L9 20L11 20L11 18 Z M 13 18L13 20L15 20L15 18 Z M 17 18L17 20L19 20L19 18Z" fill="#5B5B5B" />
                  </svg>
              </div> 
            </div>
          </div>
          <div class="md-layout-item md-size-50 mt-5"  v-if="!show[index]">
            <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block">To</label>
            <div class="flex">
              <div class="relative">
                <Datepicker 
                  label="To"
                  placeholder="Enter To Date"
                  :format="customFormatter"
                  :clearButton="true"
                  input-class="w-54 m-1 py-3 pl-3 rounded outline-gray-400"

                  v-model.trim="v.to_date.$model"
                  :message="!v.to_date.required && v.to_date.$dirty ? 'Field is required' : null"
                
                  />
                  <svg class="w-5 date-picker-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                    <path d="M5 0L5 4L7 4L7 0 Z M 17 0L17 4L19 4L19 0 Z M 1 3C0.449219 3 0 3.449219 0 4L0 7C0 7.550781 0.449219 8 1 8L1 24L23 24L23 8C23.550781 8 24 7.550781 24 7L24 4C24 3.449219 23.550781 3 23 3L20 3L20 5L16 5L16 3L8 3L8 5L4 5L4 3 Z M 3 8L21 8L21 22L3 22 Z M 5 10L5 12L7 12L7 10 Z M 9 10L9 12L11 12L11 10 Z M 13 10L13 12L15 12L15 10 Z M 17 10L17 12L19 12L19 10 Z M 5 14L5 16L7 16L7 14 Z M 9 14L9 16L11 16L11 14 Z M 13 14L13 16L15 16L15 14 Z M 17 14L17 16L19 16L19 14 Z M 5 18L5 20L7 20L7 18 Z M 9 18L9 20L11 20L11 18 Z M 13 18L13 20L15 20L15 18 Z M 17 18L17 20L19 20L19 18Z" fill="#5B5B5B" />
                  </svg>
              </div> 
            </div>
          </div>
          <div class="md-layout-item md-size-80 mt-5">
            <CheckboxComponent
              class=""
              label-class="font-bold"
              :label-outline="false"
              :block="true"
              :items="[{id: 1, label: 'To Present'}]"
              @input="getIsToPresent(index)"
              />
          </div>
          <div class="md-layout-item md-size-20 mt-12">
            <div  @click="experience_details.push({company: '', designation: '', from_date: '', to_date: ''})" class="bg-victoria pointer center w-10 h-10 rounded">
                <md-icon class="text-2xl text-white">add</md-icon>
              </div>
              <div @click="experience_details.pop()"  class="bg-danger pointer center w-10 h-10 rounded ml-1">
                <md-icon class="text-2xl text-white">remove</md-icon>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Save & Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  InputFieldComponent,
  CheckboxComponent
 
 
} from "@/components";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import { required  } from "vuelidate/lib/validators";
export default {
  components: {
    Button,
    InputFieldComponent,
    Datepicker,
    CheckboxComponent

  },

  data() {
    return {
      loading: false,
      radio: false,
      show: Object.assign({},new Array(100).join(0).split('').map(function(e) {return parseInt(e, 10);})),
     
      experience_details: [
        {
          company: '',
          designation:'',
          from_date: '',
          to_date: '',
        },
      ],
      is_experienced: true
    }
  },


  methods: {
    
    customFormatter(date) {
      return moment(date).format('YYYY-MM-D');
    },

    getIsExperience(){
      this.is_experienced = !this.is_experienced;
    },
    getIsToPresent(index){
      
      this.show[parseInt(index)] = !this.show[parseInt(index)];
      this.experience_details[parseInt(index)].to_date= !this.show[parseInt(index)]?'':this.experience_details[parseInt(index)].to_date;
    },
    submit() {


      this.$v.$touch();

      if(this.is_experienced && this.$v.$invalid) return;

      let formData = this.getLocal('job-application-basic-info');
     
      formData.experience_details = [
        ...this.experience_details
      ];
      formData.is_experienced = this.is_experienced;

      this.setLocal('job-application-basic-info', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'jobApplicationquestions' });
    },

    clearAll() {
      
      this.experience_details = [
        {
          company: '',
          designation:'',
          from_date: '',
          to_date: '',
        },
      ];

      let formData = this.getLocal('job-application-basic-info');
      console.log(formData);
      formData.experience_details = [
        ...this.experience_details
      ];

      this.$emit("menu", this.$route.name+'#false');
      this.setLocal('job-application-basic-info', formData);
    }
  },

  validations: {
    experience_details: {
      required,
      $each: {
        company: {
          required,
        },
        designation: {
          required,
        },
        from_date: {
          required,
        },
        to_date: {
          
        }
      }
    }
  },
  created() {
    let existData = this.getLocal('job-application-basic-info');

    if(existData && existData.experience_details) this.experience_details = existData.experience_details;
    if(existData && existData.is_experienced) this.is_experienced = existData.is_experienced; 
  }
}
</script>
<style scoped>
.nav-check{
  display: block!important;
}
.vdp-datepicker__clear-button{
  position: absolute;
  margin-left: -15px;
  margin-top: 15px;
  padding: -2px;
  font-weight: bold;
  color: red;
}
.date-picker-icon {
    top: 15px!important;
    right: 15px!important
}
.justify-content-center{
  justify-content: center;
}
    
</style>